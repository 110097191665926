export enum NotificationGroups {
	RebateLoadStatus = 'RebateLoadStatus',
	RebateLoadFileCreated = 'RebateLoadFileCreated',
	RebateLoadStatistic = 'RebateLoadStatistic',
	GeneratedFile = 'GeneratedFile'
}

// names from INotificationHubClientType
export enum NotificationEvents {
	UpdateRebateLoadStatus = 'UpdateRebateLoadStatus',
	RebateLoadFileCreated = 'RebateLoadFileCreated',
	RebateLoadStatisticUpdated = 'RebateLoadStatisticUpdated',
	FileGenerated = 'FileGenerated'
}

export enum HubEvents {
	Subscribe = 'Subscribe',
	UnSubscribe = 'UnSubscribe',
	SubscribeById = 'SubscribeById',
	UnSubscribeById = 'UnSubscribeById'
}
