import { createAction, props } from '@ngrx/store';
import { DownloadHistoryRequest } from 'app/models/api/download-history-request';
import { FilterBulkUpdateRequest } from 'app/models/api/filter-bulk-update-request';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { HistoryTransactionModel } from 'app/models/api/history-transaction-model';
import { RebateSearchModel } from 'app/models/api/rebate-search-model';
import { RebateUpdateModel } from 'app/models/api/rebate-update-model';
import { RebatesSearchFilterModel } from 'app/models/api/rebates-search-filter-model';
import { SearchRequest } from 'app/models/api/search-request';
import { SearchResponse } from 'app/models/api/search-response';
import { RebatesTotalCountFilterModel } from 'app/models/api/rebates-total-count-filter-model';
import { RebatesBulkDeleteRequest } from 'app/models/api/rebates-bulk-delete-request';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum RebatesUpdateActions {
	LoadRebates = '[RebatesUpdate] LoadRebates',
	RebatesLoaded = '[RebatesUpdate] RebatesLoaded',

	UpdateRebates = '[RebatesUpdate] UpdateRebates',
	RebatesUpdated = '[RebatesUpdate] RebatesUpdated',

	BulkUpdateRebates = '[RebatesUpdate] BulkUpdateRebates',
	RebatesBulkUpdated = '[RebatesUpdate] RebatesBulkUpdated',

	DeleteRebate = '[RebatesUpdate] DeleteRebate',
	RebateDeleted = '[RebatesUpdate] RebateDeleted',

	BulkDeleteRebates = '[RebatesUpdate] BulkDeleteRebates',
	RebatesBulkDeleted = '[RebatesUpdate] RebatesBulkDeleted',

	PreloadRebatesHistory = '[RebatesUpdate] PreloadRebatesHistory',
	RebatesHistoryPreloaded = '[RebatesUpdate] RebatesHistoryPreloaded',

	LoadRebatesHistory = '[RebatesUpdate] LoadRebatesHistory',
	RebatesHistoryLoaded = '[RebatesUpdate] RebatesHistoryLoaded',

	DownloadRebatesHistory = '[RebatesUpdate] DownloadRebatesHistory',
	RebatesHistoryDownloaded = '[RebatesUpdate] RebatesHistoryDownloaded',

	LoadRebatesTotalCount = '[RebatesUpdate] LoadRebatesTotalCount',
	RebatesTotalCountLoaded = '[RebatesUpdate] RebatesTotalCountLoaded',

	ErrorOccurred = '[RebatesUpdate] Error Occurred'
}

export const loadRebates = createAction(
	RebatesUpdateActions.LoadRebates,
	props<ActionRequestPayload<FilterSearchRequest<RebatesSearchFilterModel>>>()
);

export const rebatesLoaded = createAction(
	RebatesUpdateActions.RebatesLoaded,
	props<ActionResponsePayload<SearchResponse<RebateSearchModel>>>()
);

export const updateRebates = createAction(
	RebatesUpdateActions.UpdateRebates,
	props<ActionRequestPayload<RebateUpdateModel[]>>()
);

export const rebatesUpdated = createAction(RebatesUpdateActions.RebatesUpdated);

export const bulkUpdateRebates = createAction(
	RebatesUpdateActions.BulkUpdateRebates,
	props<
		ActionRequestPayload<FilterBulkUpdateRequest<RebatesSearchFilterModel>>
	>()
);

export const rebatesBulkUpdated = createAction(
	RebatesUpdateActions.RebatesBulkUpdated
);

export const deleteRebate = createAction(
	RebatesUpdateActions.DeleteRebate,
	props<ActionRequestPayload<number>>()
);

export const rebateDeleted = createAction(RebatesUpdateActions.RebateDeleted);

export const bulkDeleteRebates = createAction(
	RebatesUpdateActions.BulkDeleteRebates,
	props<ActionRequestPayload<RebatesBulkDeleteRequest>>()
);

export const rebatesBulkDeleted = createAction(
	RebatesUpdateActions.RebatesBulkDeleted
);

export const preloadRebatesHistory = createAction(
	RebatesUpdateActions.PreloadRebatesHistory
);

export const rebatesHistoryPreloaded = createAction(
	RebatesUpdateActions.RebatesHistoryPreloaded,
	props<ActionResponsePayload<SearchResponse<HistoryTransactionModel>>>()
);

export const loadRebatesHistory = createAction(
	RebatesUpdateActions.LoadRebatesHistory,
	props<ActionRequestPayload<SearchRequest>>()
);

export const rebatesHistoryLoaded = createAction(
	RebatesUpdateActions.RebatesHistoryLoaded,
	props<ActionResponsePayload<SearchResponse<HistoryTransactionModel>>>()
);

export const downloadRebatesHistory = createAction(
	RebatesUpdateActions.DownloadRebatesHistory,
	props<ActionRequestPayload<DownloadHistoryRequest>>()
);

export const rebatesHistoryDownloaded = createAction(
	RebatesUpdateActions.RebatesHistoryDownloaded
);

export const loadRebatesTotalCount = createAction(
	RebatesUpdateActions.LoadRebatesTotalCount,
	props<ActionRequestPayload<RebatesTotalCountFilterModel>>()
);

export const rebatesTotalCountLoaded = createAction(
	RebatesUpdateActions.RebatesTotalCountLoaded,
	props<ActionResponsePayload<number>>()
);

export const errorOccurred = createAction(RebatesUpdateActions.ErrorOccurred);
